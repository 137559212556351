$( document ).ready( function() {

  $("#advancedToggleHide").hide();
  $("#advancedFields").hide();

});

$("#advancedToggleShow").click(function() {
  $("#advancedToggleShow").toggle();
  $("#advancedToggleHide").toggle();
  $("#advancedFields").toggle();
});

$("#advancedToggleHide").click(function() {
  $("#advancedToggleShow").toggle();
  $("#advancedToggleHide").toggle();
  $("#advancedFields").toggle();
});

$(".description.mobile .icon").click(function() {
  $('html, body').animate({
    scrollTop: $("#categoriesList").offset().top
  }, 400);
});

(function(window, document, undefined) {
  if (window.parent) {
    window.parent.postMessage({
      type: 'URL_CHANGE',
      url: location.pathname + location.search
    }, '*');
  }

  var lastHeight = 0;

  setInterval(function() {
    var height = document.querySelector("#whole_page").scrollHeight;
    if (height !== lastHeight) {
      lastHeight = height;
      window.parent.postMessage({
        type: 'DOCUMENT_HEIGHT',
        height: lastHeight
      }, '*');
    }
  }, 120);
})(window, document);

window.addEventListener('message', function(event) {
  if (event.data) {
    switch (event.data.type) {
      case 'PARENT_URL':
        window.PARENT_LOCATION = event.data.location;
        break;
    }
  }
}, false);