var mm = window.matchMedia("(max-width: 768px)");
var lastWidth = $(window).width();

$(function() {

  mm.addListener(checkFilterDisplay());

});

var checkFilterDisplay = function() {
  
  if(mm.matches) {
    $("#filterResultsFields").hide();
    $("#filterContainerLabel").click( function(event){
      $("#filterResultsFields").toggle();
    });
  }
  else {
    $("#filterResultsText").unbind("click");
    $("#filterResultsFields").show();
  }

};

$(window).resize(function (e) {

  if(lastWidth > 768 || lastWidth > 768) {
    checkFilterDisplay();
  }
  lastWidth = e.target.innerWidth;

});