$(function() {
  $(".quantity").find("select").attr("disabled", "disabled").hide();
});

$(".adjust-payment").change(function(event){
  var element = this;

  if(this.nodeName !== "INPUT") {
    element = findSiblingInput(this);
  }
  alterTotal(element);
});

var alterTotal = function(element) {

  var base = parseInt($("#originalBaseCost").val()),
      fees = 0;
      multiplier = 1;
  
  var total = base;

  $(".product-fields input.adjust-payment").each(function(i, el){
    
    var elementFee = parseInt($(el).attr("rel"));
    var elementMultiplier = 1;

    if(el.checked) {
      if( $(el).parent().siblings(".quantity").size() > 0 ) {
        // $(el).parent().siblings(".quantity select").show();
        $(el).parent().siblings(".quantity").find("select").removeAttr("disabled").show();
        elementMultiplier = parseInt( $(el).parent().siblings(".quantity").find(":selected").text() );
      }

      fees = fees + (elementFee * elementMultiplier);
    }
    else {
      if( $(el).parent().siblings(".quantity").size() > 0 ) {
        $(el).parent().siblings(".quantity").find("select").attr("disabled","disabled").hide();
      }
    }
  });

  total = total + fees;
  $("#spnPaymentTotal").text(total.toFixed(2));

  if (total) {
    $(".payment-fields").show();
  }
  else {
    $(".payment-fields").hide();
  }
};

alterTotal($(".adjust-payment")[0]);

var findSiblingInput = function(element) {
  if( $(element).parent().siblings(".input").size() > 0 ) {
    return $(element).parent().siblings(".input").find("input");
  }
};