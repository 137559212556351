var hasMap = !!document.getElementById("groupFinderMap");

var defaultLat = 40.739079,
    defaultLng = -80.108948,
    lat = defaultLat,
    lng = defaultLng,
    zoom = 12,
    markers = [],
    infowindow = false,
    markerCluster = {},
    url = document.location.toString(),
    queryString = "",
    queryParams = [];

if(hasMap) {

  var map_options = {
    scrollwheel: false,
    zoom: zoom,
    minZoom: 1,
    maxZoom: 17,
    center: new google.maps.LatLng(lat,lng),
    mapTypeId: google.maps.MapTypeId.ROADMAP
  };

  var map = new google.maps.Map(document.getElementById("groupFinderMap"), map_options);
  var oms = new OverlappingMarkerSpiderfier(map, {
    keepSpiderfied: true
  });

  if(url.indexOf("?")>0) {
    queryString = url.substr(url.indexOf("?")+1);
    $.each(queryString.split('&'), function(i,el) {
      var param = el.split("=");
      var key = param[0].replace('%5B', '[').replace('%5D', ']');
      var value = param[1];
      queryParams[key] = value;
    });
  }

}

$(function() {
  if(hasMap) {
    getGroups();
  }
});

var getGroups = function() { 
  $.ajax({ url: '/api/v1/groups/?' + queryString })
    .done( function(data, textStatus, jqXHR) 
    {
      
      var groups = data;
      var count = groups.length;
      createGroupsListing(groups);
      
      // infowindow = new google.maps.InfoWindow({
      //     content: ''
      // });

      var styles = [
        {
          url: '/images/icons/map-cluster.png',
          height: 40,
          width: 40,
          anchor: [16, 0],
          textColor: '#fff',
          textSize: 10
        }
      ];

      for (var i = 0; i < count; i++) {
        var group = groups[i];
        var startTime = group.Meeting_Time === "unlisted" ? group.Meeting_Time : moment(group.Meeting_Time, 'YYYY-MM-DD h:mm:ss').format('h:mm A');
        var infoWindowString = createInfoListing(group);
        var latLng = new google.maps.LatLng(group.Latitude, group.Longitude);
        var marker = new google.maps.Marker(
        {
          id: group.Group_ID,
          position: latLng,
          icon:'/images/icons/map-icon.png',
          title: group.Group_Name,
          animation: google.maps.Animation.DROP,
          address1: group.Address_Line_1,
          address2: group.Address_Line_2,
          city: group.City,
          state: group.State,
          zip: group.Postal_Code,
          day: group.Meeting_Day,
          startTime: startTime,
          notes: group.Description,
        });

        marker.desc = group.Group_Name;
        oms.addMarker(marker);

        bindInfoWindow(marker, map, infoWindowString, group.Group_Name);
        markers.push(marker);
      }
      
      markerCluster = new MarkerClusterer(map, markers, {
        styles: styles,
        gridSize: 10,
        maxZoom: 14
      });
      
    })
    .fail( function(data, textStatus, jqXHR) {
      console.log("error submitting form");
    });
};

function bindInfoWindow(marker, map, html, Ltitle) { 
  infowindow = new google.maps.InfoWindow();

  google.maps.event.addListener(marker, 'click', function() {
    infowindow.setContent(html); 
    infowindow.open(map, marker);

    $(".button.more").click( function(event){
      var id = $(this).attr("rel");
      $("#g"+id).toggle();
      $("#ge"+id).toggle();
      if (window.parent) {
        window.parent.postMessage({
          type: 'SCROLL_TO',
          scrollTo: $("#ge" + id).offset().top - 50
        }, '*');
      }
      else {
        $('html, body').animate({
          scrollTop: $("#ge"+id).offset().top - 50
        }, 400);
      }
      $('#bp_group_finder iframe').contents().find('body').animate({
        scrollTop: $("#ge"+id).offset().top - 50
      }, 400);
    });
  });

}

var createInfoListing = function(group) {

  var startTime = group.Meeting_Time === "unlisted" ? group.Meeting_Time : moment(group.Meeting_Time, 'YYYY-MM-DD h:mm:ss').format('h:mm A');

  var description = group.Description ? group.Description.substring(0,100) : "";

  var contentString = "<div class='info-window-container'>";
      contentString += "<div class='headers'>";
        contentString += "<h3>" + group.Group_Name + "</h3>";
        if( group.Meeting_Day && startTime != "unlisted" ) {
          contentString += '<h4>' + group.Meeting_Day + ' at ' + startTime + '</h4>';
        }
        else if (group.Meeting_Day && startTime === "unlisted") {
          contentString += '<h4>' + group.Meeting_Day + '</h4>';
        }
      contentString += "</div>";
      contentString += '<div class="description"><p>' + description + '...</p></div>';
      contentString += '<div class="buttons"><button class="button more" rel="'+group.Group_ID+'">Learn More</button></div>';
      contentString += "</div>";

  return contentString;
};

var createGroupsListing = function(groups) {

  var count = groups.length;
  var contentString = "";
  var noGroupsMessage = '<div class="no-groups-message">We\'re sorry but there are no available groups that meet your search criteria.</div>';
  var startDate, endDate;

  if(count > 0) {
    for (var i = 0; i < count; i++) {
      var group = groups[i];
      var startTime = group.Meeting_Time === "unlisted" ? group.Meeting_Time : moment(group.Meeting_Time, 'YYYY-MM-DD h:mm:ss').format('h:mm A');
      startDate = moment(group.Start_Date);
      endDate = group.End_Date ? moment(group.End_Date) : null;

      var leaderString = "";
      $.each(group.Leaders, function(index, element){
        if( group.Leaders.length == index + 1 ) {
          leaderString += element.First_Name + ' ' + element.Last_Name;
        }
        else {
          leaderString += element.First_Name + ' ' + element.Last_Name + ', ';
        }
      });

      var addressString = "";
      addressString += group.Address_Line_1 + ", ";
      if(group.Address_Line_2) { 
        addressString += group.Address_Line_2 + ", ";
      }
      addressString += group.City + ', ' + group.State + ' ' + group.Postal_Code;

      contentString += "<div id='g"+group.Group_ID+"' class='group' rel='"+group.Group_ID+"'>";
        contentString += "<div class='icon'><i class='fa fa-plus-circle'></i></div>";
        contentString += "<div class='group_name'><span>" + group.Group_Name + "</span>";
        if( group.Meeting_Day && startTime != "unlisted" ) {
          contentString += '<div class="meeting-time">' + group.Meeting_Day + ' @ ' + startTime + '</div>';
        }
        else if (group.Meeting_Day && startTime === "unlisted") {
          contentString += '<div class="meeting-time">' + group.Meeting_Day + '</div>';
        }
        contentString += "</div>";
        contentString += '<div class="categories topics">' + group.Categories.topics + '</div>';
        contentString += '<div class="categories ages">' + group.Categories.ages + '</div>';
        contentString += '<div class="contact">' + group.First_Name + ' ' + group.Last_Name + '</div>';
      contentString += "</div>";
      contentString += "<div id='ge"+group.Group_ID+"' class='group-expanded hidden' rel='"+group.Group_ID+"'>";
        contentString += "<div class='icon'><i class='fa fa-minus-circle'></i></div>";
        contentString += "<div class='group_name'>" + group.Group_Name + "</div>";
        contentString += '<div class="description"><p>' + group.Description + '</p></div>';
        contentString += '<div class="details-container">';
          contentString += '<div class="left">';
            contentString += '<div class="leaders"><div class="label"><label>Leader(s): </label></div><div class="value">' + leaderString + '</div></div>';
            contentString += '<div class="where"><div class="label"><label>Where: </label></div><div class="value">' + addressString + '</div></div>';
            if( group.Meeting_Day && startTime != "unlisted" ) {
              contentString += '<div class="when"><div class="label"><label>When: </label></div><div class="value">' + group.Meeting_Day + ' at ' + startTime + '</div></div>';
            }
            else if (group.Meeting_Day && startTime === "unlisted") {
              contentString += '<div class="when"><div class="label"><label>When: </label></div><div class="value">' + group.Meeting_Day + '</div></div>';
            }

            contentString += '<div class="duration"><div class="label"><label>Duration: </label></div><div class="value">';

            if (endDate) {
              contentString += startDate.format('MMMM D') + ' - ' + endDate.format('MMMM D');
            }
            else {
              contentString += startDate.format('MMMM D') + ' - Ongoing';
            }

            contentString += '</div></div>';

            if (group.Meeting_Frequency) {
              contentString += '<div class="frequency"><div class="label"><label>Frequency: </label></div><div class="value">' + group.Meeting_Frequency + '</div></div>';
            }
          
          contentString += '</div>';
          contentString += '<div class="right">';
            contentString += '<div class="age-categories"><div class="label"><label>Who: </label></div><div class="categories value">' + group.AgesCsv + '</div></div>';
            contentString += '<div class="topic-categories"><div class="label"><label>Categories: </label></div><div class="categories value">' + group.TopicsCsv + '</div></div>';
            contentString += '<div style="clear:both;"></div>';
          contentString += '</div>';
          contentString += '<div class="share-container">';
            if (group.Opportunity_ID) {
              contentString += '<div><a href="/opportunities/register/' + group.Opportunity_ID + '" class="button signup">Sign Up</a></div>';
            }
            else {
              contentString += '<div><a href="/groups/register/'+group.Group_ID+'" class="button signup">Sign Up</a></div>';
            }
            contentString += '<div><a href="mailto:'+group.Email_Address+'?subject=Group Information Request" class="button contact">Contact Leaders</a></div>';
            contentString += '<div><a class="button share" rel="'+group.Group_ID+'">Share</a></div>';
            contentString += '<div id="share'+group.Group_ID+'" style="flex: 0 0 100%; margin-left: 0; margin-top: 20px"><input type="text" value="https://groups.lifeatvictory.com/groups/register/'+group.Group_ID+'" style="width: 100%;" /></div>';
          contentString += '</div>';
        contentString += '</div>';
      contentString += "</div>";
    }
  }
  else {
    contentString = noGroupsMessage;
  }

  $("#groups").append(contentString);
  $(".category.extra").hide();
  $(".group-expanded").hide();

  // register click events for newly created elements
  $(".group > .icon, .group > .group_name").click( function(event){
    $(this).parent().toggle();
    var id = $(this).parent().attr("rel");
    $("#ge"+id).toggle();
  });

  $(".group-expanded > .icon, .group-expanded > .group_name").click( function(event){
    $(this).parent().toggle();
    var id = $(this).parent().attr("rel");
    $("#g"+id).toggle();
  });

  $(".button.share").click( function(event){
    event.stopPropagation();
    var id = $(this).attr("rel");
    $("#share"+id).toggle();
    $("#share"+id).find("input").focus().select().val(getShareUrl(id));
  });

  $(".button.share").trigger("click");

  function getShareUrl(id) {
    if (window.PARENT_LOCATION) {
      return window.PARENT_LOCATION.origin + window.PARENT_LOCATION.pathname + '?gf_path=' + encodeURIComponent('/groups/register/' + id);
    }
    else {
      return location.origin + '/groups/results?id=' + id;
    }
  }
}; 